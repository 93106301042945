import React, { useEffect, useState } from 'react';
import { getProductDetails } from '../CommonProductsPages/ProductRepo';
import ProductLoadingPage from '../CommonProductsPages/ProductLoadingPage';
import ProductHeader from '../CommonProductsPages/ProductHeader';
import ProductLandingHeader from '../CommonProductsPages/ProductLandingHeader';
import Footer from '../../HomePage/Footer';

function GdProMainPage() {

    const [productLoading, setProductLoading] = useState(false);
    const [productData, setProductData] = useState({});
    const [navOpen, setNavOpen] = useState(false);

    const toggleNav = () => {
        setNavOpen(!navOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setNavOpen(false);
            }
        };
        console.log(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getProduct = () => {
        getProductDetails({
            productId: 3,
            setProductLoading,
            setProduct: setProductData,
        });
    };

    useEffect(() => { getProduct() }, []);

    const sections = [
        {
            id: '#productHome',
            sectionName: 'Home'
        },
    ]

    if (productLoading) {
        return (
            <ProductLoadingPage />
        );
    }

    return (
        <div
            className="product-description-div"
            style={{
                "--background-image": `url(${productData.imageUrl})`
            }}
        >
            <ProductHeader
                toggleNav={toggleNav}
                navOpen={navOpen}
                sections={sections}
            />
            <ProductLandingHeader
                product={productData}
                launched={false}
                launchDate={"2025-02-15T00:00:00"}
            />

            <Footer />

        </div>
    );
}

export default GdProMainPage;